import { graphql } from "gatsby";
import React, { useContext } from "react";
import { LayoutContainer } from "../containers/layout";
import PageHeadMeta from "../components/global/page-head-meta";
import { GetStarted, SectionWrapper } from "../components/sections";
import { ComparisonTable } from "../components/sections/compare-table";
import PortableText from "../components/portableText";
import { Button } from "../components/button";
import {
  DEMO_PHRASE,
  DEMO_URL,
  SIGNUP_PHRASE,
} from "../components/sections/snowflake/navigation";
import { ImageBuilder } from "../components/global/image-builder";
import { SiteContext } from "../components/global/site-context";
import { CardContext } from "../components/card/context";
import { typesMap } from "../components/sections/resources-list";
import { Card } from "../components/card/card";
import { cn, mapEdgesToNodes } from "../lib/helpers";
import { VideoPopover } from "../components/video-popover";
import { PRODUCT_VIDEO_ID } from "../components/sections/snowflake/hero";

const PAGE_WIDTH = "large";

export const query = graphql`
  query CompetitorTemplateQuery($id: String!) {
    doc: sanityCompetitor(id: { eq: $id }) {
      _type
      _id
      title
      slug {
        current
      }
      _rawExcerpt(resolveReferences: { maxDepth: 5 })
      # heroImage {
      #   ...SanityImageSimple
      # }
      page {
        ... on SanityPage {
          _type
          id
          _id
          sections {
            ... on SanityPageSectionSimple {
              _type
              _rawContent(resolveReferences: { maxDepth: 5 })
              anchor
              header
              _rawSummary(resolveReferences: { maxDepth: 5 })
              layout
              background
              padding
              container
              mainImage {
                ...SanityImageSimple
              }
              image {
                ...SanityImage
                alt
                caption
                link
              }
              tableOfContents
              rounded
              constrainImages
            }
            ... on SanityPageSectionColumns {
              _type
              content {
                header
                mainImage {
                  ...SanityImageSimple
                }
                _rawContent(resolveReferences: { maxDepth: 5 })
                author {
                  ...SanityAuthor
                }
                layout
                icon
              }
              maxColumns
              anchor
              header
              _rawSummary(resolveReferences: { maxDepth: 5 })
              layout
              background
              padding
              style
              container
              image {
                ...SanityImage
              }
            }
            ... on SanityPageComponent {
              _type
              name
              anchor
              header
              _rawSummary(resolveReferences: { maxDepth: 5 })
              layout
              background
              padding
              container
              category
              cards
            }
          }
        }
        ... on SanityOffering {
          _type
          id
          _id
          heroImage {
            ...SanityImage
          }
          problems {
            header
            _rawSummary(resolveReferences: { maxDepth: 5 })
            mainImage {
              ...SanityImage
            }
          }
        }
      }
      compare {
        _type
        _rawCrDescription(resolveReferences: { maxDepth: 5 })
        competitor
        _rawCompetitorDescription(resolveReferences: { maxDepth: 5 })
        anchor
        header
        _rawSummary(resolveReferences: { maxDepth: 5 })
        background
        padding
        container
        table {
          label
          url
          native
          compare
          isHeader
      #
        }
      }
      alternatives {
        ... on SanityCompetitor {
          _type
          title
          slug {
            current
          }
          _rawExcerpt(resolveReferences: { maxDepth: 5 })
        }
        ... on SanityPage {
          _type
          title
          slug {
            current
          }
          _rawExcerpt(resolveReferences: { maxDepth: 5 })
        }
      }
      categories {
        _id
        title
        slug {
          current
        }
      }
      faqs {
        question
        _rawAnswer(resolveReferences: { maxDepth: 5 })
        _key
      }
      problems {
        header
        _rawSummary(resolveReferences: { maxDepth: 5 })
        mainImage {
          ...SanityImage
        }
      }
    }
    stories: allSanityStory(
      sort: { fields: [orderRank], order: ASC }
      filter: {
        slug: { current: { ne: null } }
        status: { eq: "published" }
        statistics: { elemMatch: { number: { ne: null } } }
      }
      limit: 3
    ) {
      edges {
        node {
          ...cardNodeStory
        }
      }
    }
  }
`;

const CompetitorTemplate = ({ data }) => (
  <LayoutContainer doc={{ ...data.doc, backgroundColor: "lightGreen" }}>
    <PageHeadMeta title={data.doc.title} doc={data.doc} />
    {data.doc && <CompetitorPage {...data.doc} stories={mapEdgesToNodes(data.stories)} />}
  </LayoutContainer>
);

export default CompetitorTemplate;

export const CompetitorPage = ({
  title,
  compare,
  _rawExcerpt,
  heroImage,
  page,
  stories,
  faqs,
  problems = [],
}) => {
  const site = useContext(SiteContext);

  return (
    <>
      <SectionWrapper
        {...{
          noHeaders: true,
          name: "header",
          container: "thin",
          layout: "centered",
          padding: "top",
        }}
      >
        <h1 className="text-4xl sm:text-6xl font-bold text-center mt-6">{title}</h1>
        <PortableText blocks={_rawExcerpt} className="text-center text-lg my-6" />
        <div className="flex justify-center mb-16">
          <div className="flex mt-6">
            <VideoPopover
              vimeoId={PRODUCT_VIDEO_ID}
              buttonText={site.isNotMobile ? "Watch 5-min demo" : "Watch demo"}
              buttonIcon={true}
              buttonBg="bg-transparent"
              buttonClass="mr-3"
            />
            <Button
              text={DEMO_PHRASE}
              href={DEMO_URL}
              bgColor="bg-transparent"
              className="mr-3"
            />
            <Button
              text={SIGNUP_PHRASE}
              href={SIGNUP_PHRASE}
              bgColor="bg-black"
            />
          </div>
        </div>
      </SectionWrapper>

      {(heroImage) && (
        <SectionWrapper padding="bottom" layout="centered">
          <ImageBuilder image={heroImage} className="mt-12 rounded-xl mx-auto" />
        </SectionWrapper>
      )}

      {page?._type === "offering" ? (
        <SectionWrapper
          background="black"
          header={site.token("offeringsProblems")}
          container={PAGE_WIDTH}
        >
          <ul className="grid md:grid-cols-3 gap-4">
            {page.problems.map((p, index) => (
              <li
                key={index}
                className="p-8 md:p-4 md:py-6 bg-light-2 border border-light-10 rounded-xl flex flex-col justify-between"
              >
                <ImageBuilder image={p.mainImage} className="mb-4" />
                <div className="md:min-h-32">
                  <h3 className="font-medium mt-2 md:mt-0 text-2xl md:text-lg xl:text-xl mb-2">{p.header}</h3>
                  <PortableText blocks={p._rawSummary} className="text-auxiliary leading-snug" />
                </div>
              </li>
            ))}
          </ul>
        </SectionWrapper>
      ) : problems.length > 0 ? (
        <SectionWrapper
          anchor="choose"
          background="black"
          header={site.token("offeringsProblems")}
          container={PAGE_WIDTH}
        >
          <ul className="grid md:grid-cols-3 gap-4">
            {problems.map((p, index) => (
              <li
                key={index}
                className="p-8 md:p-4 md:py-6 bg-light-2 border border-light-10 rounded-xl flex flex-col justify-between"
              >
                <ImageBuilder image={p.mainImage} className="mb-4" />
                <div className="md:min-h-32">
                  <h3 className="font-medium mt-2 md:mt-0 text-2xl md:text-lg xl:text-xl mb-2">{p.header}</h3>
                  <PortableText blocks={p._rawSummary} className="text-auxiliary leading-snug" />
                </div>
              </li>
            ))}
          </ul>
        </SectionWrapper>
      ) : null}

      <SectionWrapper container={PAGE_WIDTH}>
        <ComparisonTable section={compare} />
      </SectionWrapper>

      <SectionWrapper
        container={PAGE_WIDTH}
        header="Hear it from our customers"
      >
        <div className="grid lg:grid-cols-2 xl:grid-cols-3 gap-4">
          {stories.map((doc, i) => (
            <CardContext.Provider
              key={i}
              value={{
                ...doc,
                title: ["quotable", "story"].includes(doc._type)
                  ? false
                  : doc.title,
                quote: doc._type === "quotable" ? doc : doc.quote,
                hideImage: true,
                makeThumbnail: !!doc._type === "quotable",
                showSummary: true,
                showByline: false,
                maxColumns: 3,
                hideLabels: doc._type === "quotable",
                hideType: true,
                hideDate: true,
                cta:
                  doc._type === "quotable"
                    ? null
                    : typesMap.get(doc._type)
                      ? typesMap.get(doc._type).cta
                      : typesMap.get("story"),
                showLogo: true,
                isTall: true,
                skipLink: doc._type === "quotable",
              }}
            >
              <Card />
            </CardContext.Provider>
          ))}
        </div>
      </SectionWrapper>

      {/* <SectionWrapper
        {...page.sections.find((s) => s.anchor === "beyond")}
        container={PAGE_WIDTH}
      >
        <PageSectionColumns
          section={{
            ...page.sections.find((s) => s.anchor === "beyond"),
            maxColumns: 3,
          }}
        />
      </SectionWrapper> */}

      {/* <SectionWrapper container={PAGE_WIDTH}>
        <QuoteCarousel
          section={{
            ...page.sections.find((s) => s.anchor === "quotes"),
            category: categories?.[0].slug.current,
          }}
        />
      </SectionWrapper> */}

      {/* <SectionWrapper
      container={PAGE_WIDTH}
      header="Compare alternatives"
      _rawSummary={toTextBlock(
        "Check whether Common Room is the best solution for your needs."
      )}
    >
      <ReferenceList section={{ list: alternatives }} />
    </SectionWrapper> */}

      <SectionWrapper
        container={PAGE_WIDTH}
        anchor="faqs"
        header="FAQs"
      // {...page.sections.find((s) => s.anchor === "faqs")}
      >
        <div className="max-w-screen-md">
          {faqs.map((faq, i) => (
            <div key={i} className="mb-8">
              <h3 className="text-2xl font-bold mb-4">{faq.question}</h3>
              <PortableText blocks={faq._rawAnswer} />
            </div>
          ))}
        </div>
      </SectionWrapper>

      <SectionWrapper
        background="mint"
        container={PAGE_WIDTH}
      >
        <GetStarted />
      </SectionWrapper>
    </>
  )
};
